<div class="floating-banner overflow-hidden" [class.show]="showBanner">
  <mina-leaderboard-apply></mina-leaderboard-apply>
</div>

<div class="flex-column h-100 w-100 overflow-y-scroll" #scrollContainer>
  <mina-leaderboard-apply class="w-100 flex-row"></mina-leaderboard-apply>
  <mina-leaderboard-header></mina-leaderboard-header>
  <main class="flex-1 flex-column">
    <mina-leaderboard-title></mina-leaderboard-title>
    <!--    <ng-container *ngTemplateOutlet="liveResultsInfo"></ng-container>-->
    <!--    <ng-container *ngTemplateOutlet="download"></ng-container>-->
    @if (canDownloadCSV) {
      <ng-container *ngTemplateOutlet="downloadRestricted"></ng-container>
    }
    <mina-leaderboard-filters></mina-leaderboard-filters>
    <mina-leaderboard-table></mina-leaderboard-table>
  </main>
  <mina-leaderboard-footer></mina-leaderboard-footer>
</div>

<ng-template #liveResultsInfo>
  <div class="accordion border-rad-8" (click)="isExpanded = !isExpanded">
    <div class="accordion-header fx-row-vert-cent flex-between">
      <div class="fx-row-vert-cent">
        <span class="mina-icon icon-300 mr-5">info</span>
        <span>Live results are not final because blockchain finality takes time</span>
      </div>
      <span class="mina-icon icon-300 mr-5" [@rotateIcon]="isExpanded">chevron_right</span>
    </div>
    <div class="accordion-content" [@expandCollapse]="isExpanded">
      <ul class="pt-8 pb-8 m-0">
        <li>New blocks can reorganize the chain, changing past data</li>
        <li>Network nodes need time to reach consensus</li>
        <li>Block confirmations become more certain over time</li>
        <li>Final results will be published after the program ends and complete chain verification</li>
      </ul>
      <p>To learn more about how uptime is tracked, please refer to the How Uptime Tracking Works section in the program details.</p>
    </div>
  </div>
</ng-template>

<ng-template #downloadRestricted>
  <div class="flex-column">
    <div class="download-btns fx-row-vert-cent">
      <button class="fx-row-vert-cent h-lg mr-8" (click)="downloadAll()">
        <span class="mina-icon icon-200">download</span>
        <span>Download CSV</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #download>
  <div class="flex-column">
    <p class="mt-16 mb-8">Download Public Keys that qualify for the following Prizes:</p>
    <div class="download-btns fx-row-vert-cent">
      <button class="fx-row-vert-cent h-lg mr-8" (click)="downloadUptimeLottery()">
        <span class="mina-icon icon-200">download</span>
        <span>Uptime Lottery</span>
      </button>
      <button class="fx-row-vert-cent h-lg mr-8" (click)="downloadHighestUptime()">
        <span class="mina-icon icon-200">download</span>
        <span>Highest Uptime</span>
      </button>
      <button class="fx-row-vert-cent h-lg" (click)="downloadMostProducedBlocks()">
        <span class="mina-icon icon-200">download</span>
        <span>Most Produced Blocks</span>
      </button>
    </div>
  </div>
</ng-template>
