<mina-leaderboard-apply></mina-leaderboard-apply>

<div class="flex-column h-100 w-100 overflow-scroll">
  <mina-leaderboard-header></mina-leaderboard-header>
  <main class="flex-1 flex-column w-100">
    <h2>Mina Web Node Testing Program</h2>
    <h1>Privacy Policy</h1>
    <h3><b>TBD</b></h3>
  </main>
  <mina-leaderboard-footer></mina-leaderboard-footer>
</div>
