<div class="menu fx-row-vert-cent flex-between">
  <img src="assets/images/logo/logo-text.svg" alt="" class="pointer" routerLink="">
  <span class="hamburger-trigger mina-icon icon-200 f-40" (click)="toggleMenu(); $event.stopPropagation()">{{ isMenuOpen ? 'close' : 'menu' }}</span>

  <div class="dropdown-menu fx-row-vert-cent"
       [@dropdownAnimation]="isMenuOpen ? 'open' : 'closed'"
       [class.open]="isMenuOpen"
       (clickOutside)="closeMenu()">
    <a [class.active]="route === '/leaderboard'" routerLink="/leaderboard">Leaderboard</a>
    <a target="_blank" href="https://docs.google.com/document/d/1Z8A6V2TgY9je1AyeEtA3WTFBYvyBo-eJr-QTydBsXbs/edit?usp=sharing">Program Details</a>
    <a target="_blank" href="">Prize Draw & Tie-Break Process</a>
  </div>
</div>
